import * as React from "react"
import DocumentItem from "./document-list-item"
import SectionTitle from "./section-title"
import SectionContainer from "./section-container"

const Ele = ({ reports, title }) => {
  return (
    <SectionContainer>
      <SectionTitle text={title} />
      <ul>
        {reports &&
          reports.map((report, index) => {
            return (
              <li key={index}>
                <a href={report.node.publicURL} target="_blank">
                  <DocumentItem label={report.node.name}></DocumentItem>
                </a>
              </li>
            )
          })}
      </ul>
    </SectionContainer>
  )
}
export default Ele
