import * as React from "react"
import styled from "styled-components"
import HeroBkg from "../microsites/investor-relations/images/dfy-RlIgBOphKxU-unsplash.jpg"
import Header from "../components/layout/header"
import Meta from "../utilities/seo"
import FinancialReports from "../microsites/investor-relations/financial-reports"

import Footer from "../components/layout/footer"
import { useStaticQuery, graphql } from "gatsby"

const Splash = styled.div`
  height: 42vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--c-blue-200);
  overflow: hidden;
  position: relative;

  h1 {
    font-family: var(--xn-font-body);
    font-weight: bold;
    color: #fff;
    font-size: 3rem;
    letter-spacing: -3px;
    position: relative;
    z-index: 1;
    padding-top: 5vh;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  }
`

const Hero = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 90%;

  img {
    width: 100%;
    height: 100%;
    transform: scale3d(-1, 1, 1);
    object-fit: cover;
    opacity: 40%;
  }
`

const Box = styled.main`
  background-color: #000;
  min-height: 100vh;
`

const Ele = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: { relativeDirectory: { eq: "financial-reports" } }
        sort: { order: DESC, fields: name }
      ) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `)

  const reports = data.allFile.edges

  // React.useEffect(() => {
  //   let html = document.documentElement
  //   html.classList.add("is-light")

  //   return () => {
  //     html.classList.remove("is-light")
  //   }
  // }, [])

  return (
    <>
      <Meta
        title="Investor Relations"
        description="Get the latest annual financial reports, financial statements, notices and events. "
        image={{
          url: "https://res.cloudinary.com/xeno-technologies/image/upload/v1667564767/dfy-RlIgBOphKxU-unsplash_qokzxl.jpg",
          height: "1500",
          width: "1036",
        }}
      />
      <Header />
      <Box>
        <Splash>
          <h1>Investor Relations</h1>
          <Hero>
            <img src={HeroBkg} alt="" />
          </Hero>
        </Splash>
        <div className="collection">
          <FinancialReports
            title="Abridged Financial Reports"
            reports={reports}
          />
        </div>
      </Box>
      <Footer />
    </>
  )
}
export default Ele
