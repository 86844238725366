import * as React from "react"
import styled from "styled-components"

const Box = styled.h2`
  font-family: var(--xn-font-body);
  font-size: 1.4rem;
  padding-bottom: 1rem;
  letter-spacing: -0.75px;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #fff;
  border-bottom-color: rgba(255, 255, 255, 5%);
`

const Ele = ({ text }) => {
  return <Box>{text}</Box>
}

export default Ele
