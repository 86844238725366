import * as React from "react"
import styled from "styled-components"

const Icon = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  z-index: 2;

  svg {
    width: 40%;
    height: 40%;
  }

  &:after {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--c-mint-100);
    border-radius: 100%;
    position: absolute;
    opacity: 5%;
  }
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  position: relative;
  z-index: 3;

  i {
    font-size: 0.935rem;
    letter-spacing: -0.3px;
    display: block;
    font-weight: 500;
  }

  @media (max-width: 766px) {
    i {
      width: 50%;
    }
  }
`

const Button = styled.div`
  font-size: 0.6875rem;
  display: block;
  position: relative;
  padding: 0.5rem 1rem;

  &:after {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: solid 1px rgba(0, 0, 0, 40%);
    border-color: rgba(255, 255, 255, 40%);
    border-radius: 10rem;
    position: absolute;
    opacity: 30%;
  }
`

const Box = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25rem 0;
  position: relative;
  transition: opacity 0.3s;
  color: #fff;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 0;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    border-radius: 4px;
    cursor: pointer;
    transform: scale3d(0, 1, 1);
    transform-origin: 0 0;
  }

  &:hover {
    opacity: 50%;
  }

  @media (max-width: 766px) {
    padding: 0.7rem 0;
  }
`

const Ele = ({ label }) => {
  return (
    <Box>
      <Icon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          viewBox="0 0 24 24"
        >
          <path d="M14.5 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V7.5L14.5 2z"></path>
          <path d="M14 2L14 8 20 8"></path>
          <path d="M16 13L8 13"></path>
          <path d="M16 17L8 17"></path>
          <path d="M10 9L8 9"></path>
        </svg>
      </Icon>
      <Title>
        <i>{label}</i>
        <Button>
          <span>View</span>
        </Button>
      </Title>
    </Box>
  )
}
export default Ele
